import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./_view/dashboard/dashboard.component";
import { AuthenticationLayoutComponent } from "./_shared-modules/layout/authentication-layout/authentication-layout.component";
import { DashboardLayoutComponent } from "./_shared-modules/layout/dashboard-layout/dashboard-layout.component";
import { AuthGuard } from "./_helper/guard/auth.guard";
import { ChangePasswordComponent } from "./_view/change-password/change-password.component";

const routes: Routes = [
	{
		path: "",
		component: DashboardLayoutComponent,
		children: [
			{ path: "", component: DashboardComponent },
			{ path: "change-password", component: ChangePasswordComponent },
			{
				path: "sub-admin",
				loadChildren: () =>
					import("./_view/sub-admin/sub-admin.module").then(
						(m) => m.SubAdminModule
					),
				data: {
					type: 'SUB-ADMIN'
				}
			},
			{
				path: "speaker",
				loadChildren: () =>
					import("./_view/sub-admin/sub-admin.module").then(
						(m) => m.SubAdminModule
					),
				data: {
					type: 'SPEAKER'
				}
			},
			{
				path: "activities",
				loadChildren: () =>
					import("./_view/event/event.module").then((m) => m.EventModule),
			},
			{
				path: "donation",
				loadChildren: () =>
					import("./_view/donation/donation.module").then((m) => m.DonationModule),
			},
			{
				path: "subscriber",
				loadChildren: () =>
					import("./_view/subscriber/subscriber.module").then(
						(m) => m.SubscriberModule
					),
			},
			{
				path: "query",
				loadChildren: () =>
					import("./_view/query/query.module").then((m) => m.QueryModule),
			},
		],
		canActivate: [AuthGuard],
	},
	{
		path: "authentication",
		component: AuthenticationLayoutComponent,
		children: [
			{
				path: "",
				loadChildren: () =>
					import("./_view/authetication/authetication.module").then(
						(m) => m.AutheticationModule
					),
			},
		],
	},

];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
