import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	USER = '/api/user';
	UPLOAD = '/api/upload';
	Activities = '/api/activities';
	TESTIMONIALS = '/api/testimonial';
	DYNAMIC_FORM = '/api/dynamic-form';
	BLOG = '/api/blog';
	QUERY = '/api/query';
	DONATION = '/api/donation';

	SUBSCRIBER = '/api/subscribers'
	PERMISSIONS = '/api/user-permission';
	APPLIED_FORM = '/api/applied-forms'

	WEB_EVENTS = '/api/event';
	WEB_FORMS = '/api/dynamic-form';
	WEB_APPLEID_FORM = '/api/applied-forms'

	constructor(private http: HttpClient) { }

	getUser(search) {
		return this.http.get(environment.BASE_URL + this.USER + `?${this.serialize(search)}`);
	}

	postUser(data) {
		return this.http.post(environment.BASE_URL + this.USER, data);
	}

	putUser(data) {
		return this.http.put(environment.BASE_URL + this.USER, data);
	}

	patchUser(data) {
		return this.http.patch(environment.BASE_URL + this.USER, data);
	}

	deleteUser(data) {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};
		return this.http.delete(environment.BASE_URL + this.USER, options)
	}

	changePassword(data) {
		return this.http.post(environment.BASE_URL + this.USER + '/change-password', data);
	}

	// blogs & news apis

	getBlogs(search) {
		return this.http.get(environment.BASE_URL + this.BLOG + `?${this.serialize(search)}`);
	}

	updateActivityOrder(data) {
		return this.http.put(environment.BASE_URL + this.Activities + '/updateAutoInc', data)
	}

	getBlogById(search) {
		return this.http.get(environment.BASE_URL + this.BLOG + `/get-blog-by-id?${this.serialize(search)}`);
	}

	addBlog(data) {
		return this.http.post(environment.BASE_URL + this.BLOG, data);
	}

	putBlog(data) {
		return this.http.put(environment.BASE_URL + this.BLOG, data);
	}

	patchBlog(data) {
		return this.http.patch(environment.BASE_URL + this.BLOG, data);
	}

	deleteBlogs(data) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};

		return this.http.delete(environment.BASE_URL + this.BLOG, options);
	}

	// testimonil apis
	getTestimonials(search) {
		return this.http.get(environment.BASE_URL + this.TESTIMONIALS + `?${this.serialize(search)}`);
	}

	addTestimonials(data) {
		return this.http.post(environment.BASE_URL + this.TESTIMONIALS, data);
	}

	putTestimonials(data) {
		return this.http.put(environment.BASE_URL + this.TESTIMONIALS, data);
	}

	patchTestimonials(data) {
		return this.http.patch(environment.BASE_URL + this.TESTIMONIALS, data);
	}

	updateTestimonialOrder(data) {
		return this.http.put(environment.BASE_URL + this.TESTIMONIALS + '/updateTestimonialOrder', data);
	}

	deleteTestimonials(data) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};

		return this.http.delete(environment.BASE_URL + this.TESTIMONIALS, options);
	}

	//query apis
	getQueries(search) {
		return this.http.get(environment.BASE_URL + this.QUERY + `?${this.serialize(search)}`);
	}


	getDonation(search) {
		return this.http.get(environment.BASE_URL + this.DONATION + `?${this.serialize(search)}`);
	}

	addQuery(data) {
		return this.http.post(environment.BASE_URL + this.QUERY, data);
	}

	patchQuery(data) {
		return this.http.patch(environment.BASE_URL + this.QUERY, data);
	}

	deleteQuery(data) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};

		return this.http.delete(environment.BASE_URL + this.QUERY, options);
	}

	//subscriber apis

	getSubscriber(search) {
		return this.http.get(environment.BASE_URL + this.SUBSCRIBER + `?${this.serialize(search)}`)
	}

	addSubscriber(data) {
		return this.http.post(environment.BASE_URL + this.SUBSCRIBER, data)
	}

	putSubscriber(data) {
		return this.http.put(environment.BASE_URL + this.SUBSCRIBER, data)
	}

	patchSubscriber(data) {
		return this.http.patch(environment.BASE_URL + this.SUBSCRIBER, data)
	}

	deleteSubscriber(data) {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};
		return this.http.delete(environment.BASE_URL + this.SUBSCRIBER, options)
	}

	// dynamic form apis

	putDyanmicForm(data) {
		return this.http.put(environment.BASE_URL + this.DYNAMIC_FORM, data);
	}

	getDynamicForm(search) {
		return this.http.get(environment.BASE_URL + this.DYNAMIC_FORM + `?${this.serialize(search)}`);
	}

	//event apis
	getActivities(search) {
		return this.http.get(environment.BASE_URL + this.Activities + `?${this.serialize(search)}`);
	}

	getActivity(id) {
		return this.http.get(environment.BASE_URL + '/api/website/activity/' + id);
	}

	addActivity(data) {
		return this.http.post(environment.BASE_URL + this.Activities, data);
	}

	putActivity(data) {
		return this.http.put(environment.BASE_URL + this.Activities, data);
	}

	patchActivity(data) {
		return this.http.patch(environment.BASE_URL + this.Activities, data);
	}

	deleteActivity(data) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};

		return this.http.delete(environment.BASE_URL + this.Activities, options);
	}

	//permissions apis

	getDefaultPermissions(search) {
		return this.http.get(environment.BASE_URL + this.PERMISSIONS + `/getDefaultPermissions?${this.serialize(search)}`);
	}

	fileUpload(data) {
		return this.http.post<any>(environment.BASE_URL + this.UPLOAD, data);
	}

	//applied forms apis

	getAppliedForms(search) {
		return this.http.get(environment.BASE_URL + this.APPLIED_FORM + `?${this.serialize(search)}`);
	}

	getSingleAppliedForm(search) {
		return this.http.get(environment.BASE_URL + this.APPLIED_FORM + `/id?${this.serialize(search)}`);
	}

	deleteAppliedForm(data) {

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: data
		};

		return this.http.delete(environment.BASE_URL + this.APPLIED_FORM, options);
	}


	//website apis

	//event apis

	getRandomEvents(search) {
		return this.http.get(environment.BASE_URL + this.WEB_EVENTS + `/random?${this.serialize(search)}`);
	}

	getEvents(search) {
		return this.http.post(environment.BASE_URL + this.WEB_EVENTS, search);
	}

	getEventBySpeaker(data) {
		return this.http.post(environment.BASE_URL + this.WEB_EVENTS + '/speaker', data);
	}

	eventById(search) {
		return this.http.get(environment.BASE_URL + this.WEB_EVENTS + `/id?${this.serialize(search)}`);
	}

	//dynamic form apis

	getRegistrationForm(search) {
		return this.http.get(environment.BASE_URL + this.WEB_FORMS + `?${this.serialize(search)}`);
	}

	//applied form api

	appliedForm(data) {
		return this.http.post(environment.BASE_URL + this.WEB_APPLEID_FORM, data);
	}

	serialize = (obj) => {
		var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
		return str.join("&");
	}
}
