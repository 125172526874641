import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CommonService } from '../../_services/common.service';

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

	url: any
	constructor(private cos: CommonService,
		private spinner: NgxSpinnerService,
		private router: Router) {

		this.url = this.router.url;
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		this.cos.updateSpinnerData({ showSpinner: true })

		if (!navigator.onLine) {
			this.cos.showSnackBar('Please connect to the internet first !', 3000)
			return;
		}

		const data = localStorage.getItem('niveditatrust@Token');

		if (data) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${JSON.parse(data).token}`
				}
			});
		}

		return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				if (!event.body.status) {
					// this.cos.showSnackBar(event.body.message, 3000)
				}
				//else {
				//     this.spinner.hide();
				// }
				this.cos.updateSpinnerData({ showSpinner: false })
			}
		}),
			catchError((response: any) => {
				if (response instanceof HttpErrorResponse) {
					return throwError(response);
				}
			})
		);
	}
}
