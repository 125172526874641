import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../../../_helper/interceptor/jwt.interceptor'
import { ErrorInterceptor } from '../../../_helper/interceptor/error.interceptor'
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { SwiperModule } from 'swiper/angular';
import { OnImageErrorDirective } from 'src/app/_helper/directives/on-image-error.directive';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
	declarations: [OnImageErrorDirective],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		FlexLayoutModule,
		SwiperModule,
		MaterialModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		FlexLayoutModule,
		SwiperModule,
		MaterialModule,
		OnImageErrorDirective,
		OwlDateTimeModule,
		OwlNativeDateTimeModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
	],
})
export class AddonModule { }
