<div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="mtb-4">
    <h2 class="mb-0">Price</h2>
    <button mat-icon-button aria-label="close">
		<mat-icon>close</mat-icon>
	</button>
</div>

<mat-nav-list>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Free</span>
    </a>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Paid</span>
    </a>
</mat-nav-list>