// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	BASE_URL: 'https://service.niveditatrust.com',
	firebase: {
		apiKey: "AIzaSyDp-Jl30HRETV8dUSedy1j5rSnzKqfgBiY",
		authDomain: "web-push-demo-51dd2.firebaseapp.com",
		projectId: "web-push-demo-51dd2",
		storageBucket: "web-push-demo-51dd2.appspot.com",
		messagingSenderId: "987739528147",
		appId: "1:987739528147:web:2172f637f5744ee46fed26",
		measurementId: "G-S765NMX53T"
	},
	googleClientID: '433143616493-n60agnmkh902498qabin6k5upk1uni6g.apps.googleusercontent.com',
	bucketName: 'nivedita-trust',

	websiteSecretKey: '08bb870f-6aec-4c41-be16-870f7e77efe3',
	appId: '6111641b29ff3166de62cddb'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
