<div fxLayout="row nowrap" fxLayoutAlign="space-between center">
	<h2 mat-dialog-title class="modal-title">Change Password</h2>
	<button (click)="closeModal()" mat-icon-button aria-label="close">
		<mat-icon>close</mat-icon>
	</button>
</div>

<form [formGroup]="changePasswordForm" (ngSubmit)="submitForm()">
	<mat-dialog-content>
		<div fxLayout="row wrap" class="mt-4">
			<mat-form-field>
				<mat-label>Old password</mat-label>
				<input matInput formControlName="oldPassword" [type]="hide3 ? 'password' : 'text'" />
				<button type="button" mat-icon-button matSuffix (click)="hide3 = !hide3"
					[attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
					<mat-icon>{{ hide3 ? "visibility_off" : "visibility" }}</mat-icon>
				</button>
			</mat-form-field>
			<mat-form-field>
				<mat-label>New password</mat-label>
				<input matInput formControlName="newPassword" [type]="hide1 ? 'password' : 'text'" />
				<button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1"
					[attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
					<mat-icon>{{ hide1 ? "visibility_off" : "visibility" }}</mat-icon>
				</button>
				<mat-error>Password must contain 8 characters with a combination of uppercase
					,lowercase,numeric and speical character</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>Confirm password</mat-label>
				<input matInput formControlName="confirmPassword" [type]="hide2 ? 'password' : 'text'" />
				<button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2"
					[attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
					<mat-icon>{{ hide2 ? "visibility_off" : "visibility" }}</mat-icon>
				</button>
				<mat-error>Password must contain 8 characters with a combination of uppercase
					,lowercase,numeric and speical character</mat-error>
			</mat-form-field>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button [ngClass.xs]="'fullwdith-btn'" mat-flat-button class="commomn-btn light-btn">Save</button>
	</mat-dialog-actions>
</form>