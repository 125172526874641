import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { PageService } from 'src/app/_services/page.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

	dashboard: any;

	constructor(private pageSrvc: PageService, private api: AuthService) { }

	ngOnInit(): void {
		this.pageSrvc.setPageHeader('Dashboard');
		this.pageSrvc.setPageTitle('Dashboard');
		this.getDashboard();
	}

	getDashboard() {
		this.api.dashboardCardDetails()
			.subscribe(res => {
				this.dashboard = res['data'];
			})
	}

}
