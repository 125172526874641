import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../_services/common.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private commonSrvc: CommonService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.commonSrvc.updateSpinnerData({ showSpinner: true })
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.commonSrvc.showSnackBar(err.error.message, 3000)

                // this.spinner.hide();
                // this.authSrvc.logout();
                this.commonSrvc.updateSpinnerData({ showSpinner: false })
            } else if (err.status === 400) {

                this.commonSrvc.showSnackBar(err.error.message, 3000)

                if (err.url == 'https://api.mapmyworkers.v2support.dev/api/auth/login') {
                    const attemptData = localStorage.getItem('niveditaTrust@attempts')
                    let data;
                    if (attemptData) {
                        data = {
                            count: JSON.parse(attemptData).count + 1,
                            time: new Date().setTime(new Date().getTime() + (1 * 60 * 1000))
                        }
                    } else {
                        data = {
                            count: 1,
                            time: new Date().setTime(new Date().getTime() + (1 * 60 * 1000))
                        }
                    }
                    localStorage.setItem('niveditaTrust@attempts', JSON.stringify(data))
                }
                // this.spinner.hide();
                this.commonSrvc.updateSpinnerData({ showSpinner: false })
            } else if (err.status === 404) {
                // this.spinner.hide();
                this.commonSrvc.showSnackBar(err.error.message, 3000)
                this.commonSrvc.updateSpinnerData({ showSpinner: false })
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}