<div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="mtb-4">
    <h2 class="mb-0">Category</h2>
    <button mat-icon-button aria-label="close">
		<mat-icon>close</mat-icon>
	</button>
</div>

<mat-nav-list>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Webinar</span>
    </a>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Online</span>
    </a>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Panel Discussion</span>
    </a>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Workshop</span>
    </a>
    <a href="javascript:;" mat-list-item>
        <span mat-line>Other</span>
    </a>
</mat-nav-list>