import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
	providedIn: 'root'
})
export class CommonService {

	COUNTRY_CODE = '/api/dashboard/website/countryCode'

	checkResponsive: Observable<any>;

	public spinnerSubject: BehaviorSubject<any>;
	public imageSubject: BehaviorSubject<any>;

	constructor(private http: HttpClient,
		public breakpoints: BreakpointObserver,
		private spinner: NgxSpinnerService,
		private snackBar: MatSnackBar) {


		this.checkResponsive = this.breakpoints.observe([
			Breakpoints.XSmall,
			Breakpoints.Small,
			Breakpoints.Medium,
			Breakpoints.Large,
			Breakpoints.XLarge])
			.pipe(map(breakpoint => {
				if (breakpoint.breakpoints[Breakpoints.XSmall]) {
					return { XSmall: breakpoint.breakpoints[Breakpoints.XSmall] }
				}
				if (breakpoint.breakpoints[Breakpoints.Small]) {
					return { Small: breakpoint.breakpoints[Breakpoints.Small] }
				}
				if (breakpoint.breakpoints[Breakpoints.Medium]) {
					return { Medium: breakpoint.breakpoints[Breakpoints.Medium] }
				}
				if (breakpoint.breakpoints[Breakpoints.Large]) {
					return { Large: breakpoint.breakpoints[Breakpoints.Large] }
				}
				if (breakpoint.breakpoints[Breakpoints.XLarge]) {
					return { XLarge: breakpoint.breakpoints[Breakpoints.XLarge] }
				}
			}));

		this.spinnerSubject = new BehaviorSubject<any>({});
		this.imageSubject = new BehaviorSubject<any>({});
	}

	showSnackBar(message: string, duration: number) {
		this.snackBar.open(message, undefined, {
			duration: duration, verticalPosition: "top", horizontalPosition: 'end', panelClass: 'snack-bar'
		});
	}

	getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {

		// calculate total pages
		const totalPages = Math.ceil(totalItems / pageSize);

		// ensure current page isn't out of range
		if (currentPage < 1) {
			currentPage = 1;
		} else if (currentPage > totalPages) {
			currentPage = totalPages;
		}

		let startPage: number;
		let endPage: number;
		if (totalPages <= 10) {
			// less than 10 total pages so show all
			startPage = 1;
			endPage = totalPages;
		} else {
			// more than 10 total pages so calculate start and end pages
			if (currentPage <= 6) {
				startPage = 1;
				endPage = 10;
			} else if (currentPage + 4 >= totalPages) {
				startPage = totalPages - 9;
				endPage = totalPages;
			} else {
				startPage = currentPage - 5;
				endPage = currentPage + 4;
			}
		}

		// calculate start and end item indexes
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

		// create an array of pages to ng-repeat in the pager control
		const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

		// return object with all pager properties required by the view
		return {
			totalItems,
			currentPage,
			pageSize,
			totalPages,
			startPage,
			endPage,
			startIndex,
			endIndex,
			pages
		};
	}

	getCountryCode() {
		return this.http.get(environment.BASE_URL + this.COUNTRY_CODE)
	}

	updateSpinnerData(data) {
		this.spinnerSubject.next(data);
		if (data.showSpinner == true) {
			this.spinner.show()
		} else {
			this.spinner.hide()
		}
	}

}
