import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PageService {

	title = new Subject<string>();
	header = new Subject<string>();
	constructor() { }

	setPageTitle(title: string): void {
		this.title.next(title);
	}

	setPageHeader(header: string): void {
		this.header.next(header);
	}
}
