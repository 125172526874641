import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './_shared-modules/layout/layout.module';
import { AddonModule } from './_shared-modules/core/addon/addon.module';
import { JwtModule } from '@auth0/angular-jwt';
import { DashboardComponent } from './_view/dashboard/dashboard.component';

import { MaterialModule } from './_shared-modules/core/material/material.module';
import { ChangePasswordComponent } from './_view/change-password/change-password.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { DateAgoPipe } from './_helper/pipes/date-ago.pipe';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, } from 'angularx-social-login';
import { AyleModule } from './_shared-modules/core/ayle/ayle.module';
import { EmailEditorModule } from 'angular-email-editor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DateComponent } from './_shared-modules/event-filter/date/date.component';
import { EventFilterModule } from './_shared-modules/event-filter/event-filter.module';

export function tokenGetter() {
	const data = localStorage.getItem("niveditatrust@Token")
	if (data) {
		return localStorage.getItem(JSON.parse(data).token);
	} else {
		return null;
	}
}

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		ChangePasswordComponent,
		DateAgoPipe,
		DateComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		LayoutModule,
		EventFilterModule,
		AddonModule,
		SocialLoginModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetter,
				// allowedDomains: ["example.com"],
				// disallowedRoutes: ["http://example.com/examplebadroute/"],
			},
		}),
		MaterialModule,
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AyleModule,
		EmailEditorModule,
		CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
	],
	providers: [
		AsyncPipe,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: true,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							environment.googleClientID
						),
					}
				],
			} as SocialAuthServiceConfig,
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
