import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent {

	constructor(private _bottomSheetRef: MatBottomSheetRef<PriceComponent>) { }

	openLink(event: MouseEvent): void {
		 this._bottomSheetRef.dismiss();
		 event.preventDefault();
	   }

}
