import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddonModule } from '../../_shared-modules/core/addon/addon.module';
import { FilterComponent } from './filter/filter.component';
import { PriceComponent } from './price/price.component';
import { CategoryComponent } from './category/category.component';

@NgModule({
	declarations: [
		FilterComponent,
  PriceComponent,
  CategoryComponent
	],
	imports: [
		CommonModule,
		AddonModule
	]
})
export class EventFilterModule { }
