<div fxLayout="row nowrap" fxLayoutAlign="space-between center" class="mtb-4">
    <h2 class="mb-0">Filter</h2>
    <button mat-icon-button aria-label="close">
		<mat-icon>close</mat-icon>
	</button>
</div>
<mat-accordion class="event-accordion">
    <mat-expansion-panel class="mtb-1" [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3>Date</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list class="choose-event">
            <mat-list-option>
                Tomorrow
            </mat-list-option>
            <mat-list-option>
                This Weekend
            </mat-list-option>
            <mat-list-option>
                This Week
            </mat-list-option>
            <mat-list-option>
                Next Week
            </mat-list-option>
            <mat-list-option>
                This Month
            </mat-list-option>
            <mat-list-option>
                Next Month
            </mat-list-option>
        </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel class="mtb-1" [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3>Price</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list>
            <mat-list-option>
                Free
            </mat-list-option>
            <mat-list-option>
                Paid
            </mat-list-option>
        </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel class="mtb-1" [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3>Category</h3>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list>
            <mat-list-option>
                Webinar
            </mat-list-option>
            <mat-list-option>
                Online
            </mat-list-option>
            <mat-list-option>
                Panel Discussion
            </mat-list-option>
            <mat-list-option>
                Workshop
            </mat-list-option>
            <mat-list-option>
                Other
            </mat-list-option>
        </mat-selection-list>
    </mat-expansion-panel>
</mat-accordion>