import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Gestures
import {
	HAMMER_GESTURE_CONFIG,
	HammerModule
} from '@angular/platform-browser';

/** Import Alyle UI */
import {
	LyTheme2,
	StyleRenderer,
	LY_THEME,
	LY_THEME_NAME,
	LyHammerGestureConfig
} from '@alyle/ui';

/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';


@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HammerModule,
		LyButtonModule,
		LyImageCropperModule
	],

	providers: [
		[LyTheme2],
		[StyleRenderer],
		// Theme that will be applied to this module
		{ provide: LY_THEME_NAME, useValue: 'minima-light' },
		{ provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
		{ provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
		// Gestures
		{ provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }
	]
})
export class AyleModule { }
