<mat-drawer-container class="main-dashboard" autosize>
    <mat-progress-bar *ngIf="spinner" mode="over"></mat-progress-bar>
    <mat-drawer #drawer opened class="sidenav" [mode]="sideNavMode">
        <div fxLayout="row wrap" fxLayoutAlign="center center" class="navbar-top">
            <img class="logo-icon" src="assets/images/logo.png">
        </div>
        <div class="sidenav-items">
            <mat-list>
                <a href="javascript:;" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/" class="dash hover-list">
                    <mat-icon fontSet="material-icons-outlined"> home </mat-icon>
                    <mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/">
                        Dashboard</mat-list-item>
                </a>
                <a href="javascript:;" class="dash">
                    <mat-accordion>
                        <mat-expansion-panel class="inner-sub-list">
                            <mat-expansion-panel-header class="hover-list">
                                <mat-icon fontSet="material-icons-outlined"> event_available </mat-icon>
                                <mat-list-item>
                                    Activities
                                </mat-list-item>
                            </mat-expansion-panel-header>
                            <mat-list class="sub-list">
                                <a routerLink="/activities/add" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dash hover-list">
                                    <mat-icon fontSet="material-icons-outlined"> add </mat-icon>
                                    <mat-list-item>Add</mat-list-item>
                                </a>
                                <a routerLink="/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dash hover-list">
                                    <mat-icon> list </mat-icon>
                                    <mat-list-item>List</mat-list-item>
                                </a>
                            </mat-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </a>

                <a href="javascript:;" routerLink="/query" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dash hover-list">
                    <mat-icon fontSet="material-icons-outlined"> emoji_objects </mat-icon>
                    <mat-list-item>Query</mat-list-item>
                </a>


                <a href="javascript:;" routerLink="/subscriber" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dash hover-list">
                    <mat-icon fontSet="material-icons-outlined"> share </mat-icon>
                    <mat-list-item>Subscriber</mat-list-item>
                </a>

                <a href="javascript:;" routerLink="/donation" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dash hover-list">
                    <mat-icon fontSet="material-icons-outlined"> volunteer_activism </mat-icon>
                    <mat-list-item>Donation</mat-list-item>
                </a>

                <!-- <a routerLink="/sub-admin" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dash hover-list">
                    <mat-icon fontSet="material-icons-outlined"> admin_panel_settings </mat-icon>
                    <mat-list-item>Sub Admin</mat-list-item>
                </a> -->
            </mat-list>
        </div>

    </mat-drawer>

    <mat-toolbar fxLayoutAlign="space-between center">
        <div class="left-header" fxLayout="row" fxLayoutAlign="center center">
            <button (click)="drawer.toggle()" mat-icon-button class="menu-icon">
				<mat-icon>menu</mat-icon>
			</button>
            <h2 class="page-header">{{header}}</h2>
        </div>
        <div class="profile-part">

            <button mat-button [matMenuTriggerFor]="userMenu">
				<div class="account" fxLayout="row" fxLayoutAlign="center center">
					<span class="mr-12" fxHide fxShow.gt-sm>{{userData?.name?.firstName |
						titlecase}}
						{{userData?.name?.lastName | titlecase}}</span>
					<div class="account-icon" fxLayout="row" fxLayoutAlign="center center">
						<mat-icon fontSet="material-icons-outlined"> account_circle </mat-icon>
					</div>
				</div>
			</button>

            <mat-menu #userMenu="matMenu" class="menu">
                <button (click)="openChangePasswordModal()" mat-menu-item>
					<mat-icon>lock</mat-icon>
					<span>Change Password</span>
				</button>
                <button (click)="logout()" mat-menu-item class="">
					<mat-icon fontSet="material-icons-outlined"> exit_to_app </mat-icon>
					<span>Logout</span>
				</button>

            </mat-menu>
        </div>
    </mat-toolbar>

    <div>
        <router-outlet></router-outlet>
    </div>

</mat-drawer-container>

<!-- <ngx-spinner bdOpacity=0.9 bdColor="rgba(0,0,0,0.5)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->