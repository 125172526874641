import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {

  jobRating = [
    {
      show: true
    }, {
      show: true
    }, {
      show: true
    }, {
      show: true
    }, {
      show: true
    }]

  constructor() { }

  ngOnInit(): void {
  }

}
