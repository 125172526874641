import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { RouterModule } from '@angular/router';
import { AuthenticationLayoutComponent } from './authentication-layout/authentication-layout.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { AddonModule } from '../core/addon/addon.module';

@NgModule({
	declarations: [BlankLayoutComponent, DashboardLayoutComponent, AuthenticationLayoutComponent, StarRatingComponent],
	imports: [
		CommonModule,
		RouterModule,
		AddonModule
	]
})
export class LayoutModule { }
