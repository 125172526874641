<section class="page-padding">
    <div fxLayout="row" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxLayoutGap="30px" fxLayoutGap.xs="0px"
        fxLayoutGap.sm="0px">
        <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
            <mat-card routerLink="/activities" class="dashboard-info">
                <h2>Activities</h2>
                <ul fxLayout="row" fxLayoutAlign="space-between center" class="color1">
                    <li>
                        <mat-icon>nature_people</mat-icon>
                    </li>
                    <li>{{dashboard?.activity?.count}}</li>
                </ul>
            </mat-card>
        </div>
        <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
            <mat-card routerLink="/donation" class="dashboard-info">
                <h2>Donation Request</h2>
                <ul fxLayout="row" fxLayoutAlign="space-between center" class="color2">
                    <li>
                        <mat-icon>volunteer_activism</mat-icon>
                    </li>
                    <li>{{dashboard?.donation?.count}}</li>
                </ul>
            </mat-card>
        </div>
        <div fxFlex="40" fxFlex.xs="100" fxFlex.sm="100">
            <mat-card routerLink="/query" class="dashboard-info">
                <h2>Query</h2>
                <ul fxLayout="row" fxLayoutAlign="space-between center" class="color3">
                    <li>
                        <mat-icon>question_answer</mat-icon>
                    </li>
                    <li>{{dashboard?.query?.count}}</li>
                </ul>
            </mat-card>
        </div>
    </div>
</section>