import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import { UsersService } from 'src/app/_services/users.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	changePasswordForm: FormGroup;
	hide1 = true;
	hide2 = true;
	hide3 = true;


	constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
		private commonSrvc: CommonService,
		private userSrvc: UsersService,
		private fb: FormBuilder) { }

	ngOnInit(): void {
		this.buildForm();
	}

	submitForm() {

		if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmPassword) {
			this.commonSrvc.showSnackBar('Password does not match', 3000)
		}
		const data = {
			oldPassword: this.changePasswordForm.value.oldPassword,
			newPassword: this.changePasswordForm.value.newPassword
		};

		this.userSrvc.changePassword(data)
			.subscribe((result: any) => {
				this.commonSrvc.showSnackBar(result.message, 3000)
				this.dialogRef.close();
			});
	}

	buildForm() {
		this.changePasswordForm = this.fb.group({
			oldPassword: ['', [Validators.required]],
			newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]]
		})

		// Nivedita@2022
		// $2a$08$kklCktg5nea2vHu/Z6gQzeIdl68Z2l815eVpkV76BgA/ThjkBSRHi
	}

	closeModal() {
		this.dialogRef.close()
	}

}
