import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent{

	constructor(private _bottomSheetRef: MatBottomSheetRef<CategoryComponent>) { }

	openLink(event: MouseEvent): void {
		 this._bottomSheetRef.dismiss();
		 event.preventDefault();
	   }

}
