import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageService } from './_services/page.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'dashboardTemplate';
	isDayMode = true;
	@HostBinding('class') className = '';

	constructor(
		private router: Router,
		private pageSrvc: PageService,
		private titleSrvc: Title,
		private overlay: OverlayContainer
	) {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0)
		});
	}

	ngOnInit() {

		this.pageSrvc.title.subscribe((name: string) => {
			this.titleSrvc.setTitle(name + ' | Nivedita Trust');
		});
	}

	changeTheme() {

		this.isDayMode = !this.isDayMode;

		const darkClassName = 'darkMode';
		if (this.isDayMode) {
			this.className = '';
			this.overlay.getContainerElement().classList.remove(darkClassName);
		} else {
			this.className = 'darkMode';
			this.overlay.getContainerElement().classList.add(darkClassName);
		}
	}

}
