import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import { PageService } from 'src/app/_services/page.service';
import { ChangePasswordComponent } from '../../../_view/change-password/change-password.component';

@Component({
	selector: 'app-dashboard-layout',
	templateUrl: './dashboard-layout.component.html',
	styleUrls: ['./dashboard-layout.component.scss']
})

export class DashboardLayoutComponent implements OnInit {

	panelOpenState = false;
	header = "";
	userData: any;
	sideNavMode = 'side'
	smallScreen = false;
	role = 'APPLICANT';
	logo = '';
	spinner = false;

	@ViewChild('drawer', { static: true }) sidenav: MatSidenav;

	constructor(private authSrvc: AuthService,
		private commonSrvc: CommonService,
		public dialog: MatDialog,
		public pageSrvc: PageService,
		private cdr: ChangeDetectorRef) {

	}

	ngOnInit(): void {

		this.commonSrvc.checkResponsive
			.subscribe(resp => {

				if (resp.XSmall || resp.Small || resp.Medium) {
					this.smallScreen = true;
					this.sidenav.close();
					this.sideNavMode = 'over'
				} else {
					this.smallScreen = false;
					this.sidenav.open()
					this.sideNavMode = 'side'
				}
			})

		this.pageSrvc.header.subscribe((name: string) => {

			this.header = name;
			this.cdr.detectChanges();
		});

		this.commonSrvc.spinnerSubject
			.subscribe((resp: any) => {
				this.spinner = resp.showSpinner
				this.cdr.detectChanges();
			})

		this.authSrvc.currentUserSubject
			.subscribe(resp => {
				if (resp.user) {
					this.role = resp.user.role;
					this.userData = resp.user;
				}
			})
	}


	logout() {
		this.authSrvc.logoutUser();
	}

	openChangePasswordModal() {
		const dialogRef = this.dialog.open(ChangePasswordComponent, {
			height: 'auto',
			width: '744px',
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}

}
