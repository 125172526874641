import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  LOGIN_URL = '/api/auth/login';
  FORGOT_PASSWORD = '/api/auth/forgot-password';
  RESET_PASSWORD = '/api/auth/reset-password';
  SIGN_UP = '/api/auth/adminSignUp';
  EMAIL_VERIFICATION = '/auth/email-verification';
  UPLOAD = '/upload';
  DASHBOARD_CARD_COUNT = '/api/auth/dashboardCount';
  SKILL_SUB_CATEGORY = '/auth/skillSubCategoryDetails';
  RESEND_EMAIL = '/auth/resend-email';
  LANGUAGE = '/auth/language';
  AUTH = '/api/auth';

  public currentUserSubject: BehaviorSubject<any>;
  public webUserSubject: BehaviorSubject<any>;
  public loginWithGoogleSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router) {

    this.loginWithGoogleSubject = new BehaviorSubject<any>({});

    const localData = localStorage.getItem('niveditatrust@Token');
    if (localData) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localData));
    } else {
      this.currentUserSubject = new BehaviorSubject<any>({});
    }

    const websiteData = localStorage.getItem('eventEdxWeb@Token');
    if (websiteData) {
      this.webUserSubject = new BehaviorSubject<any>(JSON.parse(websiteData));
    } else {
      this.webUserSubject = new BehaviorSubject<any>({});
    }
  }

  updateGoogleLoginData(data) {
    this.loginWithGoogleSubject.next(data);
  }

  login(body) {

    return this.http.post<any>(environment.BASE_URL + this.LOGIN_URL, body)
      .pipe(map(user => {

        if (user && user.data.token) {
          this.updateData(user.data.user);
          localStorage.setItem('niveditatrust@Token', user.data.token);
        }
        return user;
      }));
  }

  updateData(data) {
    this.currentUserSubject.next(data);
    localStorage.setItem('niveditatrust@Token', JSON.stringify(data));
  }

  updateWebsiteData(data) {
    this.webUserSubject.next(data);
    localStorage.setItem('eventEdxWeb@Token', JSON.stringify(data));
  }

  public isAuthenticated(): boolean {
    const data = localStorage.getItem('niveditatrust@Token');
    if (data && JSON.parse(data).token) {
      return !!data;
    } else {
      return false;
    }
  }

  forgotPassword(email) {
    return this.http.post(environment.BASE_URL + this.FORGOT_PASSWORD, email);
  }

  resetPassword(body) {
    return this.http.put(environment.BASE_URL + this.RESET_PASSWORD, body);
  }

  adminSignUp(data) {
    return this.http.post(environment.BASE_URL + this.SIGN_UP, data);
  }

  signUpAdminGoogle(data) {
    return this.http.post(environment.BASE_URL + '/api/auth/adminSignUpGoogle', data);
  }

  signUpSpeakerGoogle(data) {
    return this.http.post(environment.BASE_URL + '/api/auth/speakerSignUpGoogle', data);
  }

  loginWithGoogle(data) {
    return this.http.post(environment.BASE_URL + '/api/auth/googleLogin', data);
  }

  emailVerification(data) {
    return this.http.put(environment.BASE_URL + this.EMAIL_VERIFICATION, data);
  }

  reSendEmail(data) {
    return this.http.put(environment.BASE_URL + this.RESEND_EMAIL, data)
  }

  fileUpload(data) {
    return this.http.post<any>(environment.BASE_URL + '/api/upload/upload-file', data);
  }

  dashboardCardDetails() {
    return this.http.get(environment.BASE_URL + this.DASHBOARD_CARD_COUNT)
  }

  getSkillSubCategory(search) {
    return this.http.get(environment.BASE_URL + this.SKILL_SUB_CATEGORY + `?${this.serialize(search)}`)
  }

  getLanguages(search) {
    return this.http.get(environment.BASE_URL + this.LANGUAGE + `?${this.serialize(search)}`)
  }

  logoutUser() {

    localStorage.removeItem('niveditatrust@Token');
    localStorage.removeItem('webPushToken');
    this.updateData({});
    this.router.navigate(['/authentication/signin']);
  }

  loginWithLinkdIn(data) {
    return this.http.post<any>(environment.BASE_URL + '/auth/sso', data);
  }

  getLinkedInDetails(search) {
    return this.http.get(environment.BASE_URL + '/auth/getLinkedInDetails' + `?${this.serialize(search)}`)
  }

  getDetailsWithLinkedInId(search) {
    return this.http.get(environment.BASE_URL + '/auth/getLinkedInDetailsWithToken' + `?${this.serialize(search)}`)
  }

  updateDetails(data) {
    return this.http.put(environment.BASE_URL + '/auth/updateLinkedInUser', data)
  }


  // website apis
  getAccessToken(data) {
    return this.http.post(environment.BASE_URL + this.AUTH + '/access-token', data);
  }


  serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

}